.PresetDateRangePicker_button {
  border: 2px solid #02315e;
  color: #02315e;
}

.PresetDateRangePicker_button__selected {
  background: #02315e;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #02315e;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #8ab3da;
}

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-right: 33px solid #02315e;
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #8ab3da;
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-left: 33px solid #02315e;
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #8ab3da;
}

.CalendarDay__selected_span {
  background: #8ab3da;
  border: 1px double #accff1;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #accff1;
  border: 1px double #accff1;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #02315e;
  border: 1px double #02315e;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87;
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87;
}

.DateInput_input__focused {
  border-bottom: 2px solid #02315e;
}
.DateRangePicker {
  .DateInput {
    width: 110px;
    .DateInput_input {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.DateRangePicker_picker {
  z-index: 10000;
}

.DatePickerContainer {
  margin-bottom: 89px;
}

.DateRangePicker_picker {
  height: 336px !important;
}
