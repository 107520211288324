.reports-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    div { width: 100%; }
    .reports-title{
        color: var(--primary);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    .reports-section{
        background: #FFFFFF;
        box-shadow: 0px 0px 13px rgba(31, 45, 61, 0.05);
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 465px;
        padding: 30px 10px;
        width: 100%;
        .report {
            min-height: 400px;
            margin-left: 20px;
            width: 100%;
        }
        &:not(.fullwidth) .report {
            width: calc(100% - 360px);
        }
    }
}
