$drawerWidth: 277px;

$primary: #08d8cb;
// Update Bulma's global variables
$family-sans-serif: 'Lato', sans-serif;
$link: $primary;

// Update some of Bulma's component variables
$body-background-color: transparent;
$input-border-color: transparent;
$input-shadow: none;
$divider-thickness: 1px;
$box-shadow: 0 0 20px 0 rgba(31,45,61,.05);

@import 'bulma/sass/utilities/initial-variables.sass';