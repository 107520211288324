@import '../styles/vars.scss';

.reports-menu{
    .reports-menu-list {
        .reports-menu-item{
            .reports-menu-button{
                background: transparent;
                border: none;
                box-sizing: border-box;
                cursor: pointer;
                font-size: 16px;
                width: 100%;
                padding: 15px 30px 15px 20px;
                text-align: left;
                transition: all 0.3s linear;
                .button-text {
                    position: relative;
                    &:after {
                        bottom: -10px;
                        content: '';
                        display: block;
                        position: absolute;
                        left: -5px;
                        width: calc(100% + 10px);
                        transform: scaleX(0);
                        height: 2px;
                        background-color: var(--active);
                        transition: transform 0.2s linear;
                    }
                }
                &.active, &:hover {
                    .button-text {
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
                &.active {
                    font-weight: 600;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
}
