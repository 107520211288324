@import '../styles/mixins.scss';

.self-reporting-actions {
    background:white;
    justify-content: space-between;
    margin: 0 0 0 auto;
    height: 36px;
    padding: 0 10px;
    @include flex-center;
    svg {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}

#self-reporting-kpis-selection {
    margin-bottom: 20px;
    width: 100%;
    @include flex-center;
    justify-content: flex-start;
    .self-reporting-kpis-title {
        color: var(--primary);
        font-size: 16px;
        font-weight: 600;
        margin: 0 10px;
        @include large-desktop {
            font-size: 20px;
            margin: 0 20px;
        }
    }
    .self-reporting-kpis-container {
        background-color: var(--background);
        border-radius: 10px;
        color: var(--gray-text);
        padding: 10px;
        /**
         * Calc rest of the width of the section after
         * title margins (40)
         */
        width: calc(100% - 40px);
        @include flex-center;
        .self-reporting-kpis-subtitle {
            font-size: 12px;
            margin: 0 10px;
            text-transform: uppercase;
            @include large-desktop {
                font-size: 100%;
                margin: 0 20px;
            }
        }
        .self-reporting-kpis-dates-selection {
            @include flex-center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
}

.sr-kpis-overview-container {
    background: white;
    padding: 20px;
    td, thead th {
        border-left: 1px solid var(--border-color);
        box-sizing: border-box;
        color: var(--primary);
        min-width: 180px;
        text-align: center;
        &:first-child {
            border-left: none;
        }
    }
    th {
        font-size: 15px;
        padding: 10px 20px;
    }
    tr {
        &:first-child td {
            padding-top: 10px;
        }
        &:last-child td {
            padding-bottom: 10px;
        }
    }
    tbody tr td {
        font-size: 20px;
        &.positive {
            color: limegreen;
        }
        &.negative {
            color: red;
        }
    }
}

.sr-top-performing-header {
    align-items: center;
    display: flex;
    .sr-top-performing-title {
        color: var(--primary);
        font-size: 18px;
        font-weight: 600;
        margin: 40px 0;
    }
}
.sr-top-performing-table-container {
    background: white;
    margin-bottom: 60px;
    padding: 20px;
    table.table.table-pms {
        th, td {
            &:first-child{
                border-left: none;
            }
            &:last-child {
                border-right: none;
            }
        }
    }
}
