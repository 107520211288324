$large-desktop-breakpoint: 1200px;
$small-desktop-breakpoint: 992px;
$tablet-breakpoint: 768px;
$big-mobile-breakpoint: 576px;
$medium-phone-breakpoint: 414px;

@mixin large-desktop() {
    @media (min-width: 1200px) { @content; }
}

@mixin flex-center() {
    display: flex;
    align-items: center;
}
