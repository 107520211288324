@import 'assets/scss/app.variables.scss';

.container {
  align-items: stretch !important;
}

.boxContainer {
  height: 100%;
}

.aggregatedInfoContainer {
  color: #505D6E;
  min-height: 160px;
  overflow: auto;
  height: 100%;

  :global {
    .media-left {
      margin: 0 1rem;
    }
  }

  img {
    height: 60px;
  }
}

.borderRight{
	border-right: 1px solid #e2e2e2;
	padding-right: 10px;
}

.info {
	font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.value {
  font-size: 30px;
	font-weight: 700;
}

.infoBoxContainer {
  order: 2;
}

.infoBox {
  height: 100%;
  background-color: #FFFBEB;
  position: relative;
  overflow: hidden;
  text-align: left;
  color: #947600;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    background-color: #FFDD57;
  }
}

.infoBoxTitle {
  min-width: 100px;
  font-weight: bolder;
}

.noStats {
  padding: 24px;
  text-align: center;
  height: 100%;
}

@media (max-width: $widescreen) {
  .boxContainer {
    height: auto;
  }
  .infoBoxContainer {
    width: 100% !important;
    order: 0;
  }
}

@media (max-width: $desktop) {
  .aggregatedInfoContainer {
    :global {
      .media-left {
        display: none;
      }
    }
  }
}

@media (max-width: $tablet) {
  .aggregatedInfoContainer {
    img {
      height: 50px;
    }

    :global {
      .media-left {
        display: block;
      }
    }
  }
  .borderRight {
    border-right: none;
    border-bottom: 1px solid #e2e2e2;
  }
  .card {
    min-width: 100%;
  }
}

.menuOpened {
  @media (max-width: $widescreen + $drawerWidth) {
    .infoBoxContainer {
      width: 100% !important;
      order: 0;
    }
  }
  
  @media (max-width: $desktop + $drawerWidth) {
    .aggregatedInfoContainer {
      :global {
        .media-left {
          display: none;
        }
      }
    }
  }
  
  @media (max-width: $tablet + $drawerWidth) {
    .aggregatedInfoContainer {
      img {
        height: 50px;
      }
      :global {
        .media-left {
          display: block;
        }
      }
    }
    .borderRight {
      border-right: none;
      border-bottom: 1px solid #e2e2e2;
    }
    .card {
      min-width: 100%;
    }
  }

  @media (max-width: $tablet) {
    .aggregatedInfoContainer {
      :global {
        .media-left {
          display: none;
        }
      }
    }
  }
}
