.container {
  padding: 24px;
}
.image {
  width: 100px;
}
.error {
  text-align: center;
}
.title {
  text-align: left !important;
}

.preTable {
  margin-top: -24px;
}