.title {
  font-size: 11px;
}

.button {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  border: 0;
  color: #3273dc;
  :hover {
    color: #02315e;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #505d6e !important;
  a {
    color: #505d6e !important;
  }
}
.rankingContainer {
  display: flex;
  width: 60px;
  flex-wrap: nowrap;
}
