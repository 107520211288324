.group-modal {
    border-radius: 4px;
    background-color: white;
    position: fixed;
    width: 248px;
    height: 220px;
    left: calc(50% - 124px);
    top: calc(50% - 110px);
    overflow: hidden;
    .container { 
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding: 10px 25px;
        height: 220px;
        box-sizing: border-box;
    }
    .success, .confirm, .fail {
        transition: transform 0.3s linear;
        transition-delay: 0.5s;
        transform: translateY(0);

    }
    .hidden {
        display: none!important;
    }
    &.created, &.failed {
        .confirm{
            transform: translateY(-220px);
        }
        .success, .fail {
            transform: translateY(-220px);
        }
        .MuiButton-fullWidth.action-button .animated-fill{
            transition: width 0.3s linear, background-color 0.2s linear;
            width: 100%;
        }
    }
    &.failed .MuiButton-fullWidth.action-button {
        background-color: #FF889D;
        .animated-fill {
            background-color: darken(#FF889D, 10)!important;
        }
    }
}

@mixin button-styles {
    background-color: var(--active);
    color: white;
    border: none;
    &:hover {
        background-color: #07c2b6;
    }
}
.MuiButton-fullWidth.ok-button,
.MuiButton-fullWidth.action-button,
.MuiButton-fullWidth.action-button.Mui-disable {
    @include button-styles;
}

.MuiButton-fullWidth.action-button, .MuiButton-fullWidth.action-button.Mui-disabled {
    position: relative;
    transition: background-color 0.2s linear;
    .animated-fill {
        display: block;
        position: absolute;
        background-color: #059b91!important;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        transition: width 1s ease-out;
        z-index: 10;
    }
    &.creating .animated-fill {
        width: 75%;
    }
    .button-text {
        z-index: 20;
    }
}

