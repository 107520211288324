.reports-container {
    .loading-container {
        align-items: center;
        display: flex;
        justify-content: center;
        width: calc(100% - 360px);
    }
    & .reports-section.fullwidth .loading-container {
        width: 100%;
    }
}