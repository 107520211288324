@charset "utf-8";

$black: rgb(0, 0, 0);

.blue-top {
  background-color: #02315e;
  color: #fff;
}
.icon-notification {
  margin-right: 15px;
  float: left;
  padding: 7px 0px;
}
.arrow-right {
  margin: 0 15px 0 5px;
}

.color-green {
  color: #fff !important;
}
.color-green:hover {
  color: #08d8cb !important;
}
#navbarBasicExample {
  padding-left: 25px;
}
.fifty-opacity {
  color: #6a8aa8 !important;
}
.navbar-item {
  color: #fff;
  font-size: 0.8125rem;
  background: none;
  border: none;
}
.navbar-item img {
  max-height: 40px;
}
.dropdown-content {
  width: 145px;
}
.av {
  width: 40px !important;
  height: 40px !important;
}

.steps {
  text-align: center;
}
.custom-notification {
  background-color: #fefbf5;
  color: #3a2600;
}
.text-process {
  font-size: 12px;
  line-height: 16px;
  padding-top: 10px;
}

.level img {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
}

.first-section {
  margin: 10px 0;
  padding: 40px;
  box-shadow: 0 0 20px 0 rgba(31, 45, 61, 0.05) !important;
  color: #888;
}

.text-market {
  color: #888;
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
}

.control.has-icons-right .input,
.control.has-icons-right .select select {
  padding-right: 2.25em;
  border: 1px solid #d1d1d1;
}

a:hover {
  color: currentColor;
}

.layout {
  height: 100%;
}

.amcharts-amexport-menu a {
  color: $black;
}
