section.chart-error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 360px);
    div {
        text-align: center;
        svg {
            max-width: 100%;
            width: 50px;
        }
        .error-message-text {
            display: block;
            font-size: 26px;
            font-weight: bold;
            text-align: center;
        }
    }
}
.fullwidth section.chart-error-message { 
    width: 100%;
}